import React from 'react';
import { Link,useNavigate } from 'react-router-dom';
import play from "../../assests/icons/Play.png";

const RegularExerciseCard = ({ item, handleExerciseClick }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    const count = parseInt(localStorage.getItem('completedRegularExerciseCount') || '0');
    if (count >= 3) {
      // Redirect to login screen
      navigate('/login');
    } else {
      handleExerciseClick(item);
      const formattedEnum = item.exercise_enum.replace(/_/g, '-');
      navigate(`/breathing-exercises/${formattedEnum}`, { state: { exercise: item } });
    }
  };
  return (
    // <Link
    //   to={`/exercise-details-page/${item.exercise_enum}`}
    //   state={{ exercise: item }}
    //   onClick={() => handleExerciseClick(item)}
    // >
    <div onClick={handleCardClick}>
      <div className="flex flex-col justify-center mb-4">
        <div className="border-[#E8E8E8] bg-white w-full rounded-lg border-[1px]">
          <div className="flex items-center justify-between rounded-lg h-[100px]">
            <img
              src={`${process.env.REACT_APP_S3_BUCKET_URL}/${item.listing_image_url}`}
              alt={item.title}
              className="w-24 h-[100px] object-cover rounded"
            />
            <div className="flex flex-col flex-grow ml-4 gap-y-6">
              <div>
                <p className="text-xs text-[#212121]">{item.title}</p>
                <p className="text-sm font-semibold text-[#171717]">
                  {item.exercise_description}
                </p>
              </div>
              <p className="text-[10px] font-semibold text-[#212121]">
                {item.exercise_time}
              </p>
            </div>
            <button className="bg-button-bg-color mr-2 text-white p-3 rounded-full">
              <img src={play} alt="Play" />
            </button>
          </div>
        </div>
      </div>
    {/* </Link> */}
    </div>
  );
};

export default RegularExerciseCard;