import React, { useState, useEffect } from "react";
import BreatheFree from "../../assests/images/breathefree-logo-new3.png";
import StarRating from "./StarRating";
import { Link, useNavigate } from "react-router-dom";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import axios from "axios";
import { AUTH_BASE_URL } from '../../utils/ApiConstants';
const InstallAppHeader = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = localStorage.getItem("accesstoken");
    setIsLoggedIn(!!accessToken);
  }, []);
  const handleLogout = async () => {
    try {
      const accessToken = localStorage.getItem("accesstoken");
      const response = await axios.get(
        `${AUTH_BASE_URL}/api/v1/logout`,
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: accessToken,
          },
        }
      );

      if (response.data.response.code === 200) {
         // Clear tokens from localStorage
         localStorage.clear();

        // Update logged in state
        setIsLoggedIn(false);

        // Redirect to login page or home page
        navigate("/login");

        console.log("Logout successful");
      } else {
        console.log("Logout failed: Unexpected response code");
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Handle logout error here
    }
  };
  const handleDelete = async () => {
    try {
      const accessToken = localStorage.getItem("accesstoken");
      const response = await axios.get(
        `${AUTH_BASE_URL}/api/v2/delete/user_data`,
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: accessToken,
          },
        }
      );

      console.log("Delete response:", response?.data.response);
      if (response.data.response.code === 200) {
         // Clear tokens from localStorage
         localStorage.clear();

        // Update logged in state
        setIsLoggedIn(false);

        // Redirect to login page or home page
        navigate("/login");

        console.log("Logout successful");
      } else {
        console.log("Logout failed: Unexpected response code");
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error("Logout error:", error);
      // Handle logout error here
    }
  };
  return (
    <nav className="bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200">
      <div className="max-w-screen-xl flex gap-3 items-center justify-between mx-auto px-4 py-2">
        <div className="flex flex-col gap-1 items-center">
          <img src={BreatheFree} className="h-6 " alt="BreatheFree Logo" />
          <p className="text-[10px]">10 Lakh+ Installs</p>
        </div>
        {/* <div>
          <p className="text-[8px]">Breathe better everyday</p>
          <StarRating />
        </div> */}

        <a
          href="https://share.cdhl.in/breathing-in-app"
          className="bg-button-bg-color px-4 py-2 text-white rounded-lg"
          onClick={() => sendEventsToAnalyticsSystems("install_app_tap")}
        >
          Install
        </a>
        {/* {isLoggedIn && (
          <div className="flex">
          <button
            onClick={handleLogout}
            className="bg-red-500 px-4 py-2 text-white rounded-lg"
          >
            Logout
          </button>
          <button
            onClick={handleDelete}
            className="bg-red-500 px-4 py-2 text-white rounded-lg"
          >
            Delete
          </button>
          </div>
        )} */}
      </div>
    </nav>
  );
};

export default InstallAppHeader;
