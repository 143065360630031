// src/utils/ToastMessage.js
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastMessage = () => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={1000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      closeButton={false}
      toastClassName={({ type }) =>
        type === "success"
          ? "bg-button-bg-color text-white text-center"
          : type === "error"
          ? "bg-red-500 text-white text-center"
          : "bg-gray-500 text-white text-center"
      }
    />
  );
};

// Helper functions to show different types of toasts
export const showSuccessToast = (message) =>
  toast.success(message, {
    style: { background: "#4CAF50", color: "white" },
    closeButton: false,
    icon: false,
  });

export const showErrorToast = (message) =>
  toast.error(message, {
    style: { background: "#F44336", color: "white" },
    closeButton: false,
    icon: false,
  });

export const showInfoToast = (message) =>
  toast.info(message, {
    style: { background: "#2196F3", color: "white" },
    closeButton: false,
    icon: false,
  });

export const showWarningToast = (message) =>
  toast.warning(message, {
    style: { background: "#FF9800", color: "white" },
    closeButton: false,
    icon: false,
  });

export default ToastMessage;
