const { REACT_APP_BASE_URL } = process.env;
const { REACT_APP_AUTH_BASE_URL } = process.env;
const { REACT_APP_CONTENT_BASE_URL } = process.env;
const { REACT_APP_BASE_URL_WEBVIEW } = process.env;
const { REACT_APP_COMMON_URL } = process.env;
const { REACT_APP_S3_BUCKET_URL } = process.env;

export const BASE_URL = `${REACT_APP_BASE_URL}`;
export const AUTH_BASE_URL = `${REACT_APP_AUTH_BASE_URL}`;
export const BASE_CONTENT_URL = `${REACT_APP_CONTENT_BASE_URL}`;
export const BASE_URL_WEBVIEW = `${REACT_APP_BASE_URL_WEBVIEW}`;
export const BASE_COMMON_URL = `${REACT_APP_COMMON_URL}`;
export const BASE_S3_BUCKET_URL = `${REACT_APP_S3_BUCKET_URL}`;