import React, { useEffect } from "react";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";

const StopModal = ({
  isOpen,
  onClose,
  exerciseVideo,
  selectedLevel,
  onOptionChange,
  selectedOption,
  onResume,
}) => {
  useEffect(() => {
    if (isOpen) {
      sendEventsToAnalyticsSystems("exercise_stop_modal", {
        exerciseName: exerciseVideo?.exercise_enum,
        // exerciseId: exerciseVideo?.exercise_id,
        exerciseType: exerciseVideo?.exercise_type,
        level: selectedLevel,
      });
    }
  }, [isOpen, exerciseVideo]);

  if (!isOpen) return null;

  const handleChangeReason = (e) => {
    const newOption = e.target.value;
    onOptionChange(e);
    sendEventsToAnalyticsSystems("exercise_stop_tap", {
      exerciseName: exerciseVideo?.exercise_enum,
      // exerciseId: exerciseVideo?.exercise_id,
      exerciseType: exerciseVideo?.exercise_type,
      level: selectedLevel,
      tapType: "reason",
      tapValue: newOption,
    });
  };

  const handleExerciseResume=()=>{
    sendEventsToAnalyticsSystems("exercise_stop_tap", {
      exerciseName: exerciseVideo?.exercise_enum,
      // exerciseId: exerciseVideo?.exercise_id,
      exerciseType: exerciseVideo?.exercise_type,
      level: selectedLevel,
      tapType: "resume",
    });
    onResume();
  }
  return (
    <div className="fixed inset-0 flex items-end justify-center bg-black bg-opacity-50 h-75">
      <div className="w-full max-w-md bg-white rounded-t-2xl p-6">
        <div className="mb-6">
          <h3 className="text-lg font-normal text-[#171717] mb-4">
            The exercise is not over yet. Is there a reason why you are leaving?
          </h3>
          <p className="text-sm font-normal text-[#A1A1A1] ">
            Your response will help us improve your breathing regime
          </p>
        </div>
        <div className="flex flex-col mb-4">
          <label className="flex items-center py-4 border-t-2 border-[#EAEAEA]">
            <input
              type="radio"
              name="stopOption"
              value="notComfortable"
              checked={selectedOption === "notComfortable"}
              onChange={(e) => handleChangeReason(e)}
              className="w-4 h-4 mr-2 text-lg"
            />
            <span className="text-lg font-semibold">
              Not comfortable right now
            </span>
          </label>
          <label className="flex items-center py-4 border-t-2 border-[#EAEAEA]">
            <input
              type="radio"
              name="stopOption"
              value="iWillDoItLater"
              checked={selectedOption === "iWillDoItLater"}
              onChange={(e) => handleChangeReason(e)}
              className="w-4 h-4 mr-2"
            />
            <span className="text-lg font-semibold">I will do it later</span>
          </label>
        </div>
        <div className="flex mb-4">
          <button
            className="px-4 py-2 bg-white text-[#72B53D] border-2 border-[#72B53D] text-xl rounded-full w-full"
            onClick={handleExerciseResume}
          >
            Resume Exercise
          </button>
        </div>
      </div>
    </div>
  );
};

export default StopModal;
