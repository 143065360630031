import React from 'react';
import {sendEventsToAnalyticsSystems} from '../../utils/analytics'

const LearnMoreExercise = ({ exercise, ImageURL, openModal }) => {
 
    const handleOpenModal =()=>{
      openModal()
      sendEventsToAnalyticsSystems("exercise_tutorial_modal",{
        exerciseName : exercise.exercise_enum,
        exerciseType : exercise.exercise_type
      });
    } 
  return (
    <div 
      onClick={handleOpenModal} 
      className="flex items-center justify-between mx-6 border-[1px] border-slate-200 rounded-lg px-5"
    >
      <div>
        <p className="text-sm font-semibold">How to do?</p>
        <h6 className="text-button-bg-color text-base font-semibold">
          Learn more about exercise{'>'}
        </h6>
      </div>
      <div className="w-[100px] h-[100px]">
        <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/${exercise?.listing_image_url}`} alt={exercise?.title} />
      </div>
    </div>
  );
};

export default LearnMoreExercise;