import './App.css';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes, Route,
  Navigate, useLocation
} from "react-router-dom";
import BreathingExercises from './components/exercise/BreathingExercises';
import ExerciseDetailsPage from './components/exercise/ExerciseDetailsPage';
import ExerciseVideoView from './components/exercise/ExerciseVideoView';
import SubscriptionPage from './components/SubscriptionPage';
import Login from './components/exercise/Login';
import PaymentSuccessScreen from './components/exercise/PaymentSuccessScreen';
import PublicRoute from './app/route/PublicRoute';
import TermsOfUse from './components/exercise/TermsOfUse';
import PrivacyPolicy from './components/exercise/PrivacyPolicy';
import ToastMessage from './utils/Toast';
import { trackPageview } from './utils/analytics';
import BreatheFreeTv from './components/BreatheFreeTv';
import ContentID from './components/Content/ContentID';
import LocalStorageListener from './utils/LocalStorageListener';
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <PublicRoute><BreathingExercises/></PublicRoute>,
//   },
//   {
//     path: "login",
//     element: <PublicRoute><Login /></PublicRoute>,
//   },
//   // {
//   //   path: "otpverify/:phone_number",
//   //   element: <OTPVerify/>,
//   // },
//   {
//     path: "exercise-details-page/:exercise_enum",
//     element: <PublicRoute><ExerciseDetailsPage/></PublicRoute>,
//   },
//   {
//     path: "exercise-Video/:exercise_enum/video",
//     element: <ExerciseVideoView/>,
//   },
//   {
//     path: "subscription",
//     element: <SubscriptionPage />,
//   },
//   {
//     path: "payementsuccess",
//     element: <PaymentSuccessScreen />,
//   },
//   {
//     path: "termsofuse",
//     element: <TermsOfUse />,
//   },
//   {
//     path: "privacypolicy",
//     element: <PrivacyPolicy />,
//   },
// ]);
function AppContent() {
  const location = useLocation();

  useEffect(() => {
    trackPageview();
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/breathing-exercises" replace />} />
        <Route path="/breathing-exercises" element={<PublicRoute><BreathingExercises /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/breathing-exercises/:exercise_enum" element={<PublicRoute><ExerciseDetailsPage /></PublicRoute>} />
        <Route path="/breathing-exercises/:exercise_enum/video" element={<ExerciseVideoView />} />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/payementsuccess" element={<PaymentSuccessScreen />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/breathefreetv" element={<BreatheFreeTv />} />
        <Route path="/lung-health/:id" element={<ContentID />} />
      </Routes>
      <ToastMessage />
      <LocalStorageListener/>
    </>
  );
}
function App() {
  // useEffect(() => {
  //   // Track initial pageview
  //   trackPageview();

  //   // Listen for route changes
  //   const handleRouteChange = () => {
  //     trackPageview();
  //   };

  //   window.addEventListener('popstate', handleRouteChange);

  //   return () => {
  //     window.removeEventListener('popstate', handleRouteChange);
  //   };
  // }, []);
  return (
    <>
    {/* <RouterProvider router={router} />
    <ToastMessage /> */}
    <Router>
      <AppContent />
    </Router>
    </>
    
  );
}

export default App;
