import React, { memo, useState } from "react";

import BreatheFree from "../../assests/images/breathefree-logo-new3.png";

import InstallAppHeader from "./InstallAppHeader";

function BreathingNav() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
    <div className="md:hidden">
    <InstallAppHeader/>
    </div>
    
      <nav className="bg-white hidden fixed w-full z-20 top-[0] start-0 border-b md:block border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="https://play.google.com/store/apps/details?id=breathefree.lung.health.asthma.breathing"
            className="flex"
          >
            <img src={BreatheFree} className="h-6 " alt="BreatheFree Logo" />
          </a>
        </div>
      </nav>
      {/* <nav className="bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="https://play.google.com/store/apps/details?id=breathefree.lung.health.asthma.breathing"
            className="flex"
          >
            <img src={LungCareLogo} className="h-6 " alt="LungCare Logo" />
          </a>
        </div>
      </nav> */}
    </>
  );
}

export default memo(BreathingNav);
