import React, { useEffect, useState } from "react";
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import axios from 'axios';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import { BASE_CONTENT_URL, BASE_URL,BASE_S3_BUCKET_URL } from "../../utils/ApiConstants";

const Carousel = () => {
  const [splashscreen, setSplashscreen] = useState([]);
  const [carouselHeight, setCarouselHeight] = useState('40vh');


  useEffect(() => {
    const calculateCarouselHeight = () => {
      const viewportHeight = window.innerHeight;
      const headerHeight = 60; // Adjust this value based on your header height
      const footerHeight = 400; // Adjust this value based on your footer content
      const availableHeight = viewportHeight - headerHeight - footerHeight;
      setCarouselHeight(`${availableHeight}px`);
    };

    calculateCarouselHeight();
    window.addEventListener("resize", calculateCarouselHeight);

    return () => {
      window.removeEventListener("resize", calculateCarouselHeight);
    };
  }, []);
  
  const fetchCarouselData = () => {
    axios.get(`${BASE_CONTENT_URL}/api/v1/splash_screen`)
      .then((response) => {
        if (response.data.response.code === 200) {
          setSplashscreen(response.data.data.splash_screens);
        } else {
          console.error('Unexpected response code:', response.data.response.code);
        }
      })
      .catch((error) => {
        console.error('Error fetching carousel data:', error);
      });
  };
  useEffect(() => {
    fetchCarouselData();
  }, []);
  // useEffect(() => {
  //   sendEventsToAnalyticsSystems("on_splash_screen");
  //  }, [])
  return (
   
    <div className="carousel-container px-4 mt-5" >
    <Swiper
      modules={[Pagination, Autoplay]}
      pagination={{ 
        clickable: true,
        el: '.swiper-pagination',
        type: 'bullets',
      }}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      className="flex flex-col items-center h-[calc(100vh_-_450px)]"
    >
      {splashscreen.map((item) => (
        <SwiperSlide key={item.id} className="flex flex-col items-center h-full">
          <div className=" flex justify-center flex-col items-center h-full">
            <img
              src={`${BASE_S3_BUCKET_URL}/${item.image}`}
              alt={item.title}
              className="w-full max-w-[180px] h-[180px] max-h-[180px] object-contain"
            />
            <h2 className="text-center text-sm font-bold">{item.title}</h2>
          </div>
          <div className="text-center ">
            <h2 className="text-sm font-bold ">{item.title}</h2>
          </div>
          
        </SwiperSlide>
      ))}
    </Swiper>
    <div className="swiper-pagination mt-4"></div>
    <style jsx global>{`
      .swiper-pagination {
        position: static;
        margin-top: -20px;
      }
      .swiper-pagination-bullet {
        background: #ccc;
      }
      .swiper-pagination-bullet-active {
        background: #72B53D;
      }
    `}</style>
  </div>
  );
};

export default Carousel;
