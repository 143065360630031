import React from 'react'
import { Link } from 'react-router-dom'

const TermsandprivacySection = () => {
  return (
    <div className='mt-4 pb-44'>
      <div className='bg-[#FFFFFF] mt-2 border-2 border-[#EAEAEA]'>
       <Link to='/termsofuse'><p className='p-6 underline'>Terms of use</p></Link> 
      </div>
      <div className='bg-[#FFFFFF] mt-2 border-2 border-[#EAEAEA]'>
      <Link to='/privacypolicy'><p className='p-6 underline'>Privacy Policy</p></Link> 
      </div>
    </div>
  )
}

export default TermsandprivacySection