import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../../assests/json/Confetti.json";
import successMessage from "../../assests/json/Success.json";
import InstallAppHeader from "./InstallAppHeader";
import { sendEventsToAnalyticsSystems } from '../../utils/analytics';

const CompletedScreen = ({
  exerciseVideo,
  selectedLevel,
  selectedRounds,
  selectedDuration,
  selectedAccurateTime,
  onFinish,
}) => {

  const navigate = useNavigate();
useEffect(() => {
  sendEventsToAnalyticsSystems("exercise_finish_screen", {
    exerciseName: exerciseVideo.exercise_enum,
    // exerciseId: exercise.exercise_id,
    exerciseType: exerciseVideo.exercise_type,
    level:selectedLevel,
    totalTime:`${selectedAccurateTime} seconds`
  });
  
}, [])


  const onFinishWithEvents =()=>{
    onFinish();
    sendEventsToAnalyticsSystems("exercise_finish_tap", {
      exerciseName: exerciseVideo.exercise_enum,
      // exerciseId: exercise.exercise_id,
      exerciseType: exerciseVideo.exercise_type,
      level:selectedLevel,
      totalTime:`${selectedAccurateTime} seconds`
    });
  }

  // const onFinishWithEvents = () => {
  //   const count = parseInt(localStorage.getItem('completedExerciseCount') || '0');
  //   localStorage.setItem('completedExerciseCount', (count + 1).toString());

  //   sendEventsToAnalyticsSystems("exercise_finish_tap", {
  //     exerciseName: exerciseVideo.exercise_enum,
  //     exerciseType: exerciseVideo.exercise_type,
  //     level: selectedLevel,
  //     totalTime: `${selectedAccurateTime} seconds`
  //   });

  //   if (count + 1 >= 3) {
  //     // Redirect to login screen
  //     navigate('/login');
  //   } else {
  //     onFinish();
  //   }
  // };
  return (
    <>
    <div className="sm:hidden">
    <InstallAppHeader/>
    </div>
      <div className="flex flex-col justify-between w-full text-center">
        <div className="relative">
          <Lottie
            animationData={animationData}
            style={{ width: "100%", height: 300 }}
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Lottie
              animationData={successMessage}
              style={{ width: 200, height: 200 }}
            />
          </div>
        </div>

        <div className="flex-grow flex flex-col justify-end">
          <h1 className="text-4xl text-black-500 font-bold mb-2">Kudos!</h1>
          <p className="mb-4">You have completed today's exercise.</p>
          <div className="p-4 py-6 mb-4 w-full bg-gray-100">
            <div className="flex justify-between px-4 mb-2">
              <p className="text-base text-[#9C9C9C]">Exercise</p>
              <p className="text-base font-medium">
                {exerciseVideo?.exercise_description}
              </p>
            </div>
            <div className="flex justify-between px-4 mb-2">
              <p className="text-base text-[#9C9C9C]">Level</p>
              <p className="text-base font-medium">{selectedLevel}</p>
            </div>
            <div className="flex justify-between px-4 mb-2">
              <p className="text-base text-[#9C9C9C]">Rounds</p>
              <p className="text-base font-medium">{selectedRounds}</p>
            </div>
            <div className="flex justify-between px-4 mb-2">
              <p className="text-base text-[#9C9C9C]">Duration</p>
              <p className="text-base font-medium">{selectedDuration}</p>
            </div>
          </div>
          <div className="p-4 w-full fixed bottom-0">
            <button
              className="text-white w-full text-xl bg-button-bg-color hover:bg-button-bg-color focus:outline-none focus:ring-4 focus:ring-green-300 font-semibold rounded-full px-5 py-2.5 text-center me-2 mb-2 "
              onClick={onFinishWithEvents}
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedScreen;
