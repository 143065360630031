import React from 'react';
import { Link } from 'react-router-dom';
import { sendEventsToAnalyticsSystems } from '../../utils/analytics';
const ExerciseModal = ({ isOpen, closeModal, steps, currentStep, prevStep, nextStep, ImageURL, exerciseDetails, exercise, selectedRounds, selectedLevel, selectedDuration }) => {
  if (!isOpen) return null;
  // console.log(exerciseDetails?.data?.exercise_enum
  //   ,'exerciseDetails?.data?.title')
  const sendAnalyticsEvent = (tapType) => {
    const payload = {
      exerciseName: exerciseDetails?.data?.exercise_enum,
      exerciseType: exerciseDetails?.data?.exercise_type,
      tutorialPage: steps[currentStep].title,
      tapType: tapType
    };
    sendEventsToAnalyticsSystems("exercise_tutorial_tap", payload);
  };


  const handlePrevStep = () => {
    sendAnalyticsEvent("previous");
    prevStep();
  };

  const handleNextStep = () => {
    sendAnalyticsEvent("next");
    nextStep();
  };
  const handlePreStart=()=>{
    sendEventsToAnalyticsSystems("exercise_pre_start_tap", {
      exerciseName: exerciseDetails?.data?.exercise_enum,
      // exerciseId: exercise.exercise_id,
      exerciseType: exerciseDetails?.data?.exercise_type
    });
  }
  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center bg-black bg-opacity-50 h-75">
      <div className="w-full max-w-md bg-white rounded-t-2xl p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            Learn more about exercise
          </h3>
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="mb-2 px-4 py-6 bg-[#F7F7F7] rounded-lg ">
          <h3 className="text-lg font-semibold">{steps[currentStep].title}</h3>
          <p className="text-sm text-[#9A9A9A]">
            {steps[currentStep].description}
          </p>

          <img
            src={`${process.env.REACT_APP_S3_BUCKET_URL}/${steps[currentStep].image}`}
            alt={steps[currentStep].title}
            className="w-full object-bottom h-90  mb-4 "
            style={{
              objectFit:'cover',
              height:'300px',
              width:'300px',
            }}
          />
          
        </div>
        <div className="flex justify-between gap-4">
          {currentStep > 0 && (
            <button
              type="button"
              className="px-4 py-2 bg-white text-[#72B53D] border-2 border-[#72B53D] text-xl rounded-full w-full rounded disabled:opacity-50"
              onClick={handlePrevStep}
              disabled={currentStep === 0}
            >
              Previous
            </button>
          )}
          {currentStep < steps.length - 1 ? (
            <button
              type="button"
              className={`px-4 py-4 bg-button-bg-color rounded-full ${
                currentStep > 0
                  ? "bg-button-bg-color text-white font-semibold w-full text-xl"
                  : "bg-button-bg-color text-white font-semibold text-xl w-full"
              }`}
              onClick={handleNextStep}
            >
              Next
            </button>
          ) : (
            <Link
              to={`/breathing-exercises/${exerciseDetails?.data?.exercise_enum}/video`}
              state={{ exercise: exercise, selectedRounds, selectedLevel, selectedDuration }}
              className="px-4 py-2 bg-button-bg-color text-white rounded-full w-full"
            >
              <button
                type="button"
                onClick={handlePreStart}
                className="px-4 py-2 text-white font-semibold text-xl rounded w-full"
              >
                Start
              </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExerciseModal;