import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_CONTENT_URL } from "../../utils/ApiConstants";
import BackArrow from "../../assests/icons/BackArrow.png";
const TermsOfUse = () => {
  const [termsData, setTermsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = localStorage.getItem("accesstoken");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTermsOfUse = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${BASE_CONTENT_URL}/api/v3/type_wise_toc`,
          {
            content_type: "terms_of_use",
            lang_code: "en",
          },
          {
            headers: {
              accesstoken: accessToken,
            },
          }
        );
        const termsOfUseItem = response?.data?.data?.find(item => item.title === "Terms of use");
        setTermsData(termsOfUseItem);
        // setTermsData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTermsOfUse();
  }, []);
  return (
    <div className="px-3 py-4">
        <div className="">
        <div
          onClick={() => navigate(-1)}
          className="flex mb-4 items-center cursor-pointer justify-center text-center relative"
        >
          <img src={BackArrow} className="absolute left-0" alt="Back" />
          <p className="text-center text-xl font-semibold ">
           Terms of use
          </p>
        </div>
        </div>
      {/* {parseHtmlContent(termsData?.content)} */}
      <div dangerouslySetInnerHTML={{ __html: termsData?.content }} />
    </div>
  );
};

export default TermsOfUse;
