import React, { useEffect, useState } from "react";
import BreathingNav from "./BreathingNav";
import BreathingHeader from "./BreathingHeader";
import BreathingExerciseCard from "./BreathingExerciseCard";
import { BASE_URL, BASE_URL_WEBVIEW } from "../../utils/ApiConstants";
import axios from "axios";
const BreathingExercises = () => {
  const [data, setData] = useState(null);
  const fetchData = async () => {
    const accessToken = localStorage.getItem("accesstoken");
    const isLoggedIn = !!accessToken;

    let url = isLoggedIn
      ? `${BASE_URL}/breathing/exercise?lang=en`
      : `${BASE_URL_WEBVIEW}/breathing/exercise?lang=en`;

    const headers = isLoggedIn
      ? { accesstoken: accessToken, platform: "android", appVersion: "5.2" }
      : {};

    try {
      const response = await axios.get(url, { headers });
      if (response.data.response.code === 200) {
        setData(response.data.data);
      } else {
        console.error("Unexpected response code:", response.data.response.code);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className=" overflow-hidden">
      <BreathingNav />
      {/* <BreathingHeader data={data} /> */}
      <BreathingExerciseCard data={data} />
    </div>
  );
};

export default BreathingExercises;
