import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LocalStorageListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === null && e.storageArea === localStorage) {
        navigate('/');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate]);

  return null;
};

export default LocalStorageListener;