import React from "react";

const BreatheFreeTv = ({url}) => {
  return (
    <>
      <div className="fixed inset-0 w-full h-full">
        <iframe
          id="yololiv-iframe-1209771091608010752"
          src={url}
          width="100%"
          height="100%"
          className=""
          frameBorder="0"
          scrolling="auto"
          allowfullscreen="true"
          allow="autoplay; fullscreen"
        ></iframe>
      </div>
      {/* <div className="flex justify-center items-center ">
        <p>Banner</p>
      </div> */}
    </>
  );
};

export default BreatheFreeTv;
