import React, { useState, useEffect,useRef } from "react";
import InstallAppHeader from "./InstallAppHeader";
import OtpInput from "react-otp-input";
import { useParams, useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { AUTH_BASE_URL,BASE_URL } from "../../utils/ApiConstants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import editIcon from "../../assests/icons/editicon.png";
import { showErrorToast,showSuccessToast } from "../../utils/Toast";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
const OTPVerify = ({ phone_number, setVerifyOtp, token, isnew,isAgreed,iswhatsappAgreed }) => {
  const [otp, setOtp] = useState("");
  const [isVerifyEnabled, setIsVerifyEnabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [resendAttempts, setResendAttempts] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [error, setError] = useState("");
  const otpInputRefs = useRef([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Enable the verify button when OTP length is 4
    setIsVerifyEnabled(otp.length === 4);
  }, [otp]);
  // const handleVerify = () => {};
  useEffect(() => {
    if (otpInputRefs.current[0]) {
      otpInputRefs.current[0].focus();
    }
  }, []);
  const checkPremiumStatus = async (accessToken) => {
    try {
      const response = await axios.get(`${BASE_URL}/breathing/exercise?lang=en`, {
        headers: {
          accesstoken: accessToken,
          platform: "android",
          appVersion: "5.2"
        }
      });

      if (response.data.response.code === 200) {
        const mainHeaderObject = response.data.data.find(item => item.title === "mainHeader");
        if (mainHeaderObject) {
          const userIsPremium = mainHeaderObject.data.user_is_premium;
          setIsPremium(userIsPremium);
          localStorage.setItem("userIsPremium", userIsPremium.toString());
        }
      }
    } catch (error) {
      console.error("Error checking premium status:", error);
    }
  };
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);
  // const handleVerify = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${AUTH_BASE_URL}/api/v1/user/otp_verify`,
  //       {
  //         is_new: isnew,
  //         phone_code: "+91",
  //         phone_number: phone_number,
  //         otp: otp,
  //       },
  //       {
  //         headers: {
  //           timestamp: Date.now().toString(),
  //           accesstoken: token,
  //         },
  //       }
  //     );

  //     console.log("OTP verification response:", response.data);
  //     if (
  //       response?.data?.response?.code === 200 &&
  //       response?.data?.response?.status === "success"
  //     ) {
  //       if (
  //         response?.data?.data?.access_token &&
  //         response.data.data?.refresh_token
  //       ) {
  //         // Store the tokens in localStorage
  //         localStorage.setItem(
  //           "accesstoken",
  //           response?.data?.data?.access_token
  //         );
  //         localStorage.setItem(
  //           "refreshtoken",
  //           response?.data?.data?.refresh_token
  //         );
  //         localStorage.setItem(
  //           "secondary_uuid",
  //           response?.data?.data?.secondary_uuid
  //         );
  //         localStorage.setItem("completedRegularExerciseCount", 0);
  //         localStorage.setItem("mobileNumber", phone_number);
  //         console.log("Tokens stored in localStorage");
  //         try {
  //           const onboardingResponse = await axios.post(
  //             `${AUTH_BASE_URL}/api/v1/onboarding/webview`,
  //             {
  //               whatsapp_consent: iswhatsappAgreed,
  //               web_consent: true,
  //               terms_conditions: isAgreed
  //             },
  //             {
  //               headers: {
  //                 accesstoken: token,
  //                 timestamp: Date.now().toString(),
  //               }
  //             }
  //           );
  //           console.log("Onboarding response:", onboardingResponse.data);
  //           // Handle the onboarding response as needed
  //         } catch (onboardingError) {
  //           console.error("Onboarding API error:", onboardingError);
  //           // Handle onboarding error
  //         }
          
  //       } else {
  //         console.log("Tokens not found in the response");
  //       }
  //       // Handle successful verification here
  //       // For example, navigate to the next page or show a success message
  //       navigate("/");
  //     } else {
  //       console.log("error");
  //     }
  //     // Check if the response contains the tokens
  //   } catch (error) {
  //     console.error("OTP verification error:", error);
  //     setError(error.response?.data?.response?.alert?.[0].message);

  //     // Handle verification error here
  //     // For example, show an error message to the user
  //   }
  // };
  useEffect(() => {
    sendEventsToAnalyticsSystems("on_otpverify_screen",{
      phone_code:"+91",
      userPhoneNumber:phone_number,
      tapType:"login"
    });
   }, [])
  const handleVerify = async () => {
    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v1/user/otp_verify`,
        {
          is_new: isnew,
          phone_code: "+91",
          phone_number: phone_number,
          otp: otp,
        },
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: token,
          },
        }
      );
  
      console.log("OTP verification response:", response.data);
      if (
        (response?.data?.response?.code === 200 || response?.data?.response?.code === 201) &&
        response?.data?.response?.status === "success"
      ) {
        if (
          response?.data?.data?.access_token &&
          response.data.data?.refresh_token
        ) {
          // Store the tokens in localStorage
          localStorage.setItem(
            "accesstoken",
            response?.data?.data?.access_token
          );
          localStorage.setItem(
            "refreshtoken",
            response?.data?.data?.refresh_token
          );
          localStorage.setItem(
            "secondary_uuid",
            response?.data?.data?.secondary_uuid
          );
          localStorage.setItem("completedRegularExerciseCount", 0);
          localStorage.setItem("mobileNumber", phone_number);
          sendEventsToAnalyticsSystems("on_otpverify_tap", {
            userPhoneNumber: phone_number,
            // exerciseNumber: index + 1,
            verify_otp: 'yes',
            user_unique_id:response?.data?.data?.secondary_uuid
          });
          // Call the onboarding API
          try {
            const onboardingResponse = await axios.post(
              `${AUTH_BASE_URL}/api/v1/onboarding/webview`,
              {
                whatsapp_consent: iswhatsappAgreed,
                web_consent: true,
                terms_conditions: isAgreed
              },
              {
                headers: {
                  accesstoken: response?.data?.data?.access_token,
                  timestamp: Date.now().toString(),
                }
              }
            );
            await checkPremiumStatus(response?.data?.data?.access_token);
            // Handle the onboarding response as needed
          } catch (onboardingError) {
            console.error("Onboarding API error:", onboardingError);
            // Handle onboarding error
          }
          
          // Navigate to the home page
          // navigate("/");
          const fromPremiumExercise = localStorage.getItem('fromPremiumExercise');
          const premiumUser = localStorage.getItem('userIsPremium');
          if (fromPremiumExercise === 'true' && premiumUser !=="true") {
            // Clear the localStorage items
            localStorage.removeItem('fromPremiumExercise');
            // Navigate to subscription page
            navigate('/subscription');
          } else {
            navigate("/");
          }
        } else {
          console.log("Tokens not found in the response");
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      setError(error.response?.data?.response?.alert?.[0].message);
    }
  };
  const handleResendOtp = async () => {
    if (countdown > 0) return;

    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v1/resend/otp`,
        {
          phone_code: "+91",
          phone_number: phone_number,
          tapType: "resend",
        },
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: token,
            appVersion: "5.8",
            deviceBrand: "google",
            deviceModel: "sdk_gphone64_arm64",
            device_name: "sdk_gphone64_arm64",
            deviceVersion: "14",
            platform: "android",
          },
        }
      );

      console.log("OTP resent successfully:", response.data);
      setResendAttempts(resendAttempts + 1);
      setCountdown(30 + resendAttempts * 30); // Increment by 30 seconds each time
      showSuccessToast('OTP resent successfully!');
    } catch (error) {
      console.error("Error resending OTP:", error);
      showErrorToast("Failed to resend OTP. Please try again.");
    }
  };
  return (
    <>
      {/* <InstallAppHeader /> */}
      {/* <Carousel /> */}
      {/* <div className="w-full z-20 bottom-0 start-0  rounded-t-5xl  fixed">
        <div className="bg-gradient-to-r from-purple-400 to-purple-600 h-[100px]  rounded-t-3xl text-white font-sm text-center p-2 absolute top-[-50px] -z-10 w-full">
          <p className="pt-1">
            Install app now and earn{" "}
            <span className="font-sm">500 breathefree coins</span>
          </p>
        </div> */}
      <div className="max-w-md mx-auto px-4 rounded-lg rounded-t-3xl bg-white pb-5 relative z-10 py-6">
        {/* <div className="p-1 mt-3">
            <h2 className="text-2xl font-bold mb-2">Login to Breathefree</h2>
            <p className="mb-4">Enter your phone number to receive OTP</p>
            <div className="mb-4">
              <label
                htmlFor="phoneNumber"
                className="block text-gray-700 font-bold mb-1"
              >
                Phone number<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="phoneNumber"
                placeholder="Eg. 9000000000"
                className="w-full p-2 border border-gray-300 rounded-lg"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="flex">
              <div className="flex items-start mb-4">
                <input
                  type="checkbox"
                  id="whatsapp"
                  className="mr-2 custom-checkbox"
                  checked={iswhatsappAgreed}
                  onChange={(e) => setIsWhatsAppAgreed(e.target.checked)}
                />
                <label htmlFor="whatsapp" className="text-gray-700 text-sm">
                  I agree to give permission for whatsapp
                </label>
              </div>
            </div>
            <div className="flex">
              <div className="flex items-start mb-4">
                <input
                  type="checkbox"
                  id="agreement"
                  className="mr-2"
                  checked={isAgreed}
                  onChange={(e) => setIsAgreed(e.target.checked)}
                />
                <label htmlFor="agreement" className="text-gray-700 text-sm">
                  I agree to Breathefree's{" "}
                  <a href="#" className="text-green-500 underline">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="#" className="text-green-500 underline">
                    Terms & Conditions
                  </a>
                </label>
              </div>
              <button
                className={`px-4 py-4 rounded-2xl text-white ${
                  isAgreed && phoneNumber
                    ? "bg-green-500"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
                disabled={!isAgreed || !phoneNumber}
              >
                Continue
              </button>
            </div>
          </div> */}
        {/* <div className="max-w-md mx-auto px-4 rounded-lg rounded-t-3xl bg-white pb-5 relative z-10 py-6">
            <div className="p-1 mt-3">
              <h2 className="text-2xl font-semibold mb-2">Verify OTP</h2>
              <p className="mb-4 text-sm font-normal">Sent to +91 9850687986</p>

              <div className="flex">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className=" h-[50px] mr-4 border-2 border-gray-300 rounded text-center text-5xl"
                    />
                  )}
                />
              </div>
              <div className="flex justify-between">
                <div className="flex items-center mb-4">Didn’t receive it?</div>

                <button
                className="bg-button-bg-color text-base font-semibold px-5 py-4 rounded-full text-white"
                // className={`text-base font-semibold px-5 py-4 rounded-full text-white ${
                //   isButtonEnabled
                //     ? "bg-button-bg-color"
                //     : "bg-gray-300 cursor-not-allowed"
                // }`}
                // disabled={!isButtonEnabled}
                // onClick={handleLoginLClick}
                >
                  Verify
                </button>
              </div>
            </div>
          </div> */}
        <div className="bg-white px-4 rounded-b-3xl ">
          <ToastContainer />
          <h2 className="text-2xl font-bold mb-4">Verify OTP</h2>

          <p className="text-sm mb-4 flex">
            Sent to +91 {phone_number}{" "}
            <span
              onClick={() => {
                setVerifyOtp(false);
              }}
              className="text-[#3B8500] cursor-pointer"
            >
              <div className="ml-2 flex items-center">
                <img src={editIcon} className="h-full w-full" />
                <p className="text-xs font-semibold ml-1"> EDIT</p>
              </div>
            </span>
          </p>

          <p className="text-sm font-semibold mb-2">
            Enter OTP<span className="text-red-500">*</span>
          </p>

          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType="tel"
            renderSeparator={<span className="w-2"></span>}
            placeholder={true}
            renderInput={(props, index) => (
              <input
                {...props}
                ref={(el) => (otpInputRefs.current[index] = el)}
                placeholder="0"
                style={{
                  width: '3.5rem',
                  height: '3.5rem',
                  fontSize: '1.5rem',
                  border: '1px solid #EBEBEB',
                  borderRadius: '0.375rem',
                  textAlign:'center',
                  transition: 'background-color 0.3s, border-color 0.3s',
                }}
                className="otp-input"
                onChange={(e) => {
                  props.onChange(e);
                  if (e.target.value && index < 3) {
                    otpInputRefs.current[index + 1].focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace' && !e.target.value && index > 0) {
                    otpInputRefs.current[index - 1].focus();
                  }
                }}
              />
            )}
           
            focusedBorderColor='#FFBB00'
            containerStyle="justify-start"
          />
          <p className="text-red-600">{error}</p>
          <div className="flex justify-between items-center mt-6">
            {countdown > 0 ? (
              <p>
                Resend OTP in {Math.floor(countdown / 60)}:
                {countdown % 60 < 10 ? "0" : ""}
                {countdown % 60}{" "}
              </p>
            ) : (
              <p className="text-xs font-normal text-[#999999]">
                Didn't receive it?{" "}
                <span
                  className="text-xs text-[#3B8500] ml-1 font-semibold cursor-pointer"
                  onClick={handleResendOtp}
                >
                  RESEND OTP
                </span>
              </p>
            )}

            <button
              className={`text-base font-semibold px-7 py-4 rounded-full text-white ${
                isVerifyEnabled
                  ? "bg-button-bg-color"
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              onClick={handleVerify}
              disabled={!isVerifyEnabled}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default OTPVerify;
