export const getSeoData = (exerciseDescription) => {
    switch (exerciseDescription) {
      case "Thoracic expansion technique":
        return {
          title: "Thoracic expansion breathing technique: Benefits & how to do",
          description: "Practice thoracic expansion exercise to enhance upper back mobility, reduce tightness, and for better spinal health."
        };
      case "Breathe control technique":
        return {
          title: "Breathe control technique: Benefits & how to do",
          description: "Explore breath control technique which offer numerous benefits such as stress reduction, enhanced lung strength, cardiovascular health, digestive function, and skin vitality."
        };
      case "Breath stacking technique":
        return {
          title: "Breath stacking technique: Benefits & how to do",
          description: "Learn the breath stacking technique to helps improve lung capacity, oxygen intake, and secretion clearance."
        };
      case "The Buteyko method":
        return {
          title: "The Buteyko breathing: Benefits & how to do",
          description: "The buteyko method uses nasal breathing, breath control and breath-holding exercises to assist people with many different health conditions."
        };
      case "Hands opening thoracic expansion":
        return {
          title: "Hands opening thoracic expansion breathing exercise: Benefits & how to do",
          description: "Hands opening thoracic expansion exercise helps to clear pulmonary secretions, reduce chest infection risk, clear mucus and debris from airways and improve respiratory health."
        };
      case "Box breathing":
        return {
          title: "Box breathing technique: Benefits & how to do",
          description: "Discover box breathing, a deep breathing technique which helps reduce stress and anxiety by calming the nervous system and lowering cortisol levels."
        };
      case "4-7-8 breathing":
        return {
          title: "4-7-8 breathing exercise: Benefits & how to do",
          description: "Explore 4-7-8 breathing technique which helps reduce stress and anxiety. It can also improve sleep quality by calming the mind and preparing the body for rest."
        };
      case "Rib cage breathing":
        return {
          title: "Rib cage breathing exercise: Benefits & how to do",
          description: "Perform Rib Cage Breathing with us to enhances lung capacity and promotes relaxation through deep, controlled breathing."
        };
      case "Wim hof breathing method":
        return {
          title: "Wim hof breathing method: Benefits & how to do",
          description: "The Wim Hof Method helps to combine breathing, cold therapy and commitment to help you connect more deeply to your body. It involves powerful inhalation, relaxed exhalation, and prolonged breath holds."
        };
      case "Bubbles blowing- breathing with straw":
        return {
          title: "Bubbles blowing- breathing with straw: Benefits & how to do",
          description: "Blowing bubbles with a straw offers stress relief, fine motor skill enhancement, and childlike wonder."
        };
      case "Candle blowing breathing":
        return {
          title: "Candle blowing breathing exercise: Benefits & how to do",
          description: "Discover Candle blowing exercise which offers calming effects, normalizes breathing and heart rate, reduces stress, and aids sleep."
        };
      case "Spirometer exerciser breathing":
        return {
          title: "Spirometer exerciser breathing exercise: Benefits & how to do",
          description: "Learn spirometer exercises to maintain healthy air sacs where oxygen and carbon dioxide exchange occurs"
        };
      case "Reverse spirometer breathing exercise":
        return {
          title: "Reverse spirometer breathing exercise: Benefits & how to do",
          description: "Reverse spirometer breathing exercises help maintain healthy air sacs where oxygen and carbon dioxide exchange occurs"
        };
      case "Balloon blowing exercise":
        return {
          title: "Balloon blowing exercise: Benefits & how to do",
          description: "Perform balloon blowing exercises to enhance lung capacity, strengthen respiratory muscles, improve breathing technique, promote better posture, and boost overall well-being."
        };
      case "Dirga Pranayama":
        return {
          title: "Dirga Pranayama | Breathing exercise: Benefits & how to do",
          description: "Practice Dirga Pranayama (Three-Part Breath) to enhance oxygenation, reduce stress, and prepare for meditation."
        };
      case "Aulom vilom":
        return {
          title: "Aulom vilom | Breathing exercise: Benefits & how to do",
          description: "Learn Anulom Vilom Pranayama, also known as Alternate Nostril Breathing Technique, which offers numerous benefits such as stress reduction, enhanced lung strength, cardiovascular health, digestive function, and skin vitality."
        };
      case "Bhastrika Pranayama":
        return {
          title: "Bhastrika Pranayama | Breathing exercise: Benefits & how to do",
          description: "Discover Bhastrika Pranayama, the \"yogic breath of fire,\" which boosts energy, focus, and detoxification. It improves lung capacity, sinuses, bronchitis, lung function, and sensory awareness."
        };
      case "Pursed Lip Breathing":
        return {
          title: "Pursed lip breathing exercise: Benefits & how to do",
          description: "Discover the benefits of pursed lip breathing and follow our simple guide to practice it. Improve lung health, reduce breathlessness, and find relaxation.",
        };
      case "Diaphragmatic Breathing":
        return {
          title: "Diaphragmatic breathing exercise: Benefits & how to do",
          description: "Boost lung efficiency with diaphragmatic breathing. This exercise strengthens your diaphragm and enhances overall breathing. Learn how to practice it effectively.",
        };
      case "Huff Puff Technique":
        return {
          title: "Huff puff technique: Benefits & how to do",
          description: "Learn the Huff Puff technique to remove mucus and clear your lungs. Discover how to practice this method for improved respiratory health.",
        };
      case "Slow-Paced Breathing":
        return {
          title: "Slow-paced breathing exercise: Benefits & how to do",
          description: "Explore how slow-paced breathing leads to fitter lungs and prevents wheezing, coughing, and breathlessness. Use our guide for effective practice and better respiratory health.",
        };
      default:
        return {
          title: `${exerciseDescription}: Purpose, Benefits & Guide`,
          description: `Learn about ${exerciseDescription}, its benefits, and how to practice it effectively for improved respiratory health.`,
        };
    }
  };