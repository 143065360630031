import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { format } from 'date-fns'
import sanitizeHtml from 'sanitize-html';
import Share from '../../assests/icons/whatsapp.png';

const ContentID = () => {
  const { id } = useParams()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_CONTENT_BASE_URL}/api/v1/webview/content/details`, { content_id: id })
      if(response?.data?.response?.code === 200){
        setData(response?.data?.data)
        setLoading(false)
      }else{
        navigate("/")
        setLoading(false)
      }
    } catch (error) {
      navigate("/")
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!id) return
    fetchData();
  }, [id]);

  return (
    <>
      <Helmet>
        {data?.content_detail?.content_title && <title>{data?.content_detail?.content_title}</title>}
        {data?.content_detail?.content_title && <meta name="title" content={data?.content_detail?.content_title} />}
        {data?.content_detail?.thumbnail_image && <meta name="og:image" content={`${process.env.REACT_APP_S3_BUCKET_URL_CONTENT}/${data.content_detail.thumbnail_image}`} />}
      </Helmet>
      <section>
        <div className="max-w-lg mx-auto relative">
          {
            loading ?
              <div className='w-5/6 aspect-video animate-pulse bg-gray-400 mx-auto mt-5 rounded-md shadow-sm' /> :
              <>
                {
                  data?.content_detail?.thumbnail_image && (
                    <figure className='w-full aspect-video'>
                      <img className='object-cover w-full h-full' src={`${process.env.REACT_APP_S3_BUCKET_URL}/${data.content_detail.thumbnail_image}`} alt="thumbnail" />
                    </figure>
                  )
                }
                <div className='py-4 w-5/6 mx-auto'>
                  {data?.content_detail?.created_on && <span className='text-gray-500 text-sm'>{format(new Date(data.content_detail.created_on), 'dd MMM, yyyy')}</span>}
                  {data?.content_detail?.content_title && <h2 className='text-2xl font-bold'>{data.content_detail.content_title}</h2>}
                  {data?.content_detail?.html_contents && <div className='[&_a]:text-blue-500 [&_a]:underline prose [&_*]:my-0' dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.content_detail.html_contents) }} />}
                </div>
              </>
          }
        </div>
      </section>
      <a className='fixed bottom-10 right-5 shadow-md p-3 rounded-full bg-[#40C351]' href={`https://api.whatsapp.com/send?text=${data?.content_detail?.content_title}%20${window.location.href}`} target="_blank" rel="noopener noreferrer">
        <img className='w-8 h-8' src={Share} alt="Share" />
      </a>
    </>
  )
}

export default ContentID