import React, { useState, useEffect, useRef } from "react";
import InstallAppHeader from "./InstallAppHeader";
import Carousel from "./Carousel";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import OTPVerify from "./OTPVerify";
import { AUTH_BASE_URL } from "../../utils/ApiConstants";
import BfCoins from "../../assests/icons/BFcoins.png";
import {
  sendEventsToAnalyticsSystems,
  trackPageview,
} from "../../utils/analytics";
const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAgreed, setIsAgreed] = useState(true);
  const [iswhatsappAgreed, setIsWhatsAppAgreed] = useState(true);
  const [authResult, setAuthResult] = useState(null);
  const [verifyotp, setVerifyOtp] = useState(false);
  const [isnew, setIsnew] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const phoneInputRef = useRef(null);
  const authenticateUser = async () => {
    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v1/authenticate`,
        {
          application_id: process.env.REACT_APP_APPLICATION_ID,
          fingerprint: "goldfish_arm64",
          device_type: "MOBILE",
          device_name: "google",
          os: "android",
          os_version: "14",
          browser: "",
          browser_version: "",
          application_version: "5.8",
          last_active: "2022-03-26 16:39:59",
        }
      );

      if (response.data.response.code === 200) {
        setAuthResult(response.data.data);
      } else {
        console.error("Unexpected response code:", response.data.response.code);
      }
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };
  useEffect(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("accesstoken");
    if (token) {
      // If a token exists, redirect to home or dashboard
      navigate("/");
    } else {
      // If no token, authenticate the app
      authenticateUser();
    }
  }, [navigate]);
  // const handleLoginLClick = () => {
  //   navigate("/otpverify");
  // };
  const handleLoginLClick = async () => {
    try {
      const response = await axios.post(
        `${AUTH_BASE_URL}/api/v2/user/login_register`,
        {
          phone_code: "+91",
          phone_number: phoneNumber,
          terms_conditions: isAgreed,
        },
        {
          headers: {
            timestamp: Date.now().toString(),
            accesstoken: authResult?.access_token,
          },
        }
      );

      
      if (
        response?.data?.response?.code === 200 &&
        response?.data?.response?.status === "success"
      ) {
        sendEventsToAnalyticsSystems("on_login_tap", {
          phone_code: "+91",
          phone_number:phoneNumber,
          terms_conditions:isAgreed,
          tapType:"continue",
        });
        setVerifyOtp(true);
        setIsnew(response?.data?.data?.is_new);
      }else{
      }
    } catch (error) {
      console.error("Login/Register error:", error);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };
  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const getInputClassName = () => {
    let className =
      "w-full border rounded-lg py-2 transition-all duration-300 outline-none ";

    if (isFocused) {
      className += "p-2 ";
      if (phoneNumber.length > 0 && phoneNumber.length < 10) {
        className += "border-[#72B53D] bg-[#F1F9EA] ";
      } else {
        className += "border-gray-300 ";
      }
    } else {
      className += "p-0 border-gray-300 ";
    }

    return className;
  };
  useEffect(() => {
    sendEventsToAnalyticsSystems("on_login_screen");
    trackPageview();
  }, []);
  const isButtonEnabled = phoneNumber.length === 10 && isAgreed;
  return (
    <>
      <InstallAppHeader />
      <div className="w-full">
        <Carousel />
      </div>

      <div className="w-full z-20 bottom-0 start-0  rounded-t-5xl  fixed">
        <div className="bg-gradient-to-r from-purple-400 to-purple-600 h-[100px]  rounded-t-3xl text-white font-sm text-center p-2 absolute top-[-40px] -z-10 w-full">
          <p className="text-[12px] font-medium pt-1  flex justify-center">
            Install app now and earn{" "}
            <img src={BfCoins} className="w-[20px] h-[20px]" />
            <span className="text-[12px] font-bold">500 breathefree coins</span>
          </p>
        </div>
        {!verifyotp ? (
          <div className="max-w-md mx-auto px-4 rounded-lg rounded-t-3xl bg-white pb-5 relative z-10 py-3">
            <div className="p-1 mt-3">
              <h2 className="text-2xl font-semibold mb-2">
                Login to Breathefree
              </h2>
              <p className="mb-4 text-sm font-normal">
                Enter your phone number to receive OTP
              </p>
              <div className="mb-4 relative">
                <label
                  htmlFor="phoneNumber"
                  className="block text-base font-medium mb-1"
                >
                  Phone number<span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {isFocused && (
                    <span
                      className={`absolute left-3 top-1/2 transform -translate-y-1/2 text-[#1F1F1F] transition-opacity duration-300 ${
                        isFocused ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      +91
                    </span>
                  )}

                  <input
                    type="tel"
                    id="phoneNumber"
                    placeholder={isFocused ? "" : "Eg. 9000000000"}
                    className={getInputClassName()}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    maxLength={10}
                    ref={phoneInputRef}
                    style={{ paddingLeft: isFocused ? "45px" : "8px" }}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="flex items-start mb-4">
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="whatsapp"
                      checked={iswhatsappAgreed}
                      onChange={(e) => setIsWhatsAppAgreed(e.target.checked)}
                    />
                    <span className="checkmark">
                      <svg viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                      </svg>
                    </span>
                    <span className="text-[10px] font-medium">
                      I agree to give permission for WhatsApp
                    </span>
                  </label>
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="flex items-start mb-4">
                  <label className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="agreement"
                      checked={isAgreed}
                      onChange={(e) => setIsAgreed(e.target.checked)}
                    />
                    <span className="checkmark">
                      <svg viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                      </svg>
                    </span>
                    <span className="text-[10px] font-medium">
                      I agree to Breathefree's <br />
                      <Link
                        to="/privacypolicy"
                        className="text-button-bg-color"
                      >
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link to="/termsofuse" className="text-button-bg-color">
                        Terms & Conditions
                      </Link>
                    </span>
                  </label>
                </div>

                <button
                  className={`text-base font-semibold px-4 py-1 rounded-full text-white ${
                    isButtonEnabled
                      ? "bg-button-bg-color"
                      : "bg-gray-300 cursor-not-allowed"
                  }`}
                  disabled={!isButtonEnabled}
                  onClick={handleLoginLClick}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        ) : (
          <OTPVerify
            phone_number={phoneNumber}
            setVerifyOtp={setVerifyOtp}
            token={authResult?.access_token}
            isnew={isnew}
            isAgreed={isAgreed}
            iswhatsappAgreed={iswhatsappAgreed}
          />
        )}
      </div>
    </>
  );
};

export default Login;
