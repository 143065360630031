import React from 'react';

const LevelTable = ({ levelInformation, selectedLevel, handleLevelChange }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th className="px-2 py-3"></th>
          <th className="px-2 py-3 text-left text-xs font-medium text-[#929292] lowercase tracking-wider">
            Level
          </th>
          <th className="px-2 py-3 text-left text-xs font-medium text-[#929292] lowercase tracking-wider">
            Rounds
          </th>
          <th className="px-2 py-3 text-left text-xs font-medium text-[#929292] lowercase tracking-wider">
            Duration
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {levelInformation?.map((item, index) => (
          <tr key={index} onClick={() => handleLevelChange(item.level, item.rounds, item.duration)}>
            <td>
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="radio"
                    name="selectedLevel"
                    className="hidden"
                    value={item.level}
                    checked={selectedLevel === item.level}
                    onChange={() => handleLevelChange(item.level, item.rounds, item.duration)}
                  />
                  <div
                    className={`w-6 h-6 border-2 rounded-full border-slate-100 ${
                      selectedLevel === item.level ? "bg-button-bg-color " : ""
                    }`}
                  >
                    {selectedLevel === item.level && (
                      <div className="flex justify-center items-center w-full h-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="white"
                          className="w-4 h-4"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 4.707 7.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </label>
            </td>
            <td className="px-2 whitespace-nowrap">
              <div className="flex items-center">
                <span className="ml-2 text-sm font-medium">{item.level}</span>
              </div>
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-sm font-medium">
              {item.rounds}
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-sm font-medium">
              {item.duration}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LevelTable;