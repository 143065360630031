import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import BackArrow from "../../assests/icons/BackArrow.png";
import { Link } from "react-router-dom";
import axios from "axios";
import playicon from "../../assests/icons/playicon.png";
import ExerciseModal from "./ExerciseModal";
import LevelTable from "./LevelTable";
import LearnMoreExercise from "./LearnMoreExercise";
import { BASE_URL, BASE_URL_WEBVIEW } from "../../utils/ApiConstants";
import InstallAppHeader from "./InstallAppHeader";
import Loader from "./Loader";
import { sendEventsToAnalyticsSystems } from "../../utils/analytics";
import { Helmet } from "react-helmet";
import { getSeoData } from "../../utils/getSeoData";
import SeoHelmet from '../../utils/SeoHelmet'
const ExerciseDetailsPage = ({ data }) => {
  const { exercise_enum } = useParams();
  const originalExerciseEnum = exercise_enum.replace(/-/g, '_');
  const location = useLocation();
  const exercise = location.state?.exercise;
  const [exerciseDetails, setExerciseDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState("Intermediate");
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRounds, setSelectedRounds] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [selectedAccurateTime, setselectedAccurateTime] = useState(0);
  const [filteredData, setFilteredData] = useState({});
  // const exercise_enum = exercise.exercise_enum;
  const navigate = useNavigate();
  const ImageURL = `${process.env.REACT_APP_S3_BUCKET_URL}`;
  const practiceVideoUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/${exercise?.practice_video_url}`;
  useEffect(() => {
    const fetchExerciseDetails = async () => {
      setLoading(true);
      const access_Token = localStorage.getItem("accesstoken");
      const isLoggedIn = !!access_Token;
      try {
        let url = isLoggedIn
          ? `${BASE_URL}/breathing/exercise/detail`
          : `${BASE_URL_WEBVIEW}/breathing/exercise/detail`;
        const headers = isLoggedIn
          ? {
              accesstoken: access_Token,
              platform: "android",
              appVersion: "5.2",
            }
          : {};
        const response = await axios.post(
          url,
          {
            exercise_enum: originalExerciseEnum,
          },
          { headers: headers }
        );
       if (response.data.response.code === 200) {
      setExerciseDetails(response.data);

      const defaultLevel = "Intermediate";
      const defaultRounds = response.data.data.level_information.find(
        (level) => level.level === defaultLevel
      );

      setSelectedRounds(defaultRounds.rounds);
      setSelectedDuration(defaultRounds.duration);
      setselectedAccurateTime(defaultRounds.accurate_duration);
    } else {
      setError("Unexpected response code: " + response.data.response.code);
    }
      } catch (error) {
        setError("Failed to fetch exercise details");
      } finally {
        setLoading(false);
      }
    };

    fetchExerciseDetails();
  }, [originalExerciseEnum]);
  // console.log(exerciseDetails?.data, "exerciseDetails");
  // console.log(exercise, "exerciseDetails1223");
  // const fetchData = async () => {
  //   const res = await fetch(`${BASE_URL}/breathing/exercise?lang=en`, {
  //     method: "GET",
  //   });
  //   const response = await res.json();
  //   console.log(response,"response")
  //   const categoryWithExercise = response?.data?.find(category =>
  //     category.data.some(ex => ex.exercise_enum === exercise_enum)
  //   );
  //   console.log(categoryWithExercise,'categoryWithExercise')
  //   const BreathingExerciseDatails = response?.data?.find(item => item.exercise_category === "GUIDED BREATHING");
  //   console.log(BreathingExerciseDatails,'BreathingExerciseDatails')
  //   const foundExercise = BreathingExerciseDatails?.data?.find(ex => ex.exercise_enum === exercise_enum);
  //   setFilteredData(foundExercise)
  //   // console.log(foundExercise,'foundExercise')
  // };
  const fetchData = async () => {
    const accessToken = localStorage.getItem("accesstoken");
    const headers = {
      accesstoken: accessToken,
      platform: "android",
      appVersion: "5.2",
    };
    const isLoggedIn = !!accessToken;
    let url = isLoggedIn
      ? `${BASE_URL}/breathing/exercise?lang=en`
      : `${BASE_URL_WEBVIEW}/breathing/exercise?lang=en`;
  
    try {
      const response = await axios.get(url, {
        headers: isLoggedIn ? headers : {},
      });
  
      if (response.data.response.code === 200) {
        const exerciseData = response.data.data.filter(item => 
          item.title === "ExerciseList" && item.exercise_category
        );
  
        const categoryWithExercise = exerciseData.find((category) =>
          category.data.some((ex) => ex.exercise_enum === originalExerciseEnum)
        );
  
        if (categoryWithExercise) {
          const foundExercise = categoryWithExercise.data.find(
            (ex) => ex.exercise_enum === originalExerciseEnum
          );
          setFilteredData(foundExercise);
        } else {
          console.log("Exercise not found");
          setFilteredData(null);
        }
      } else if (response.data.response.code === 401) {
        console.log("Response code is 401, clearing local storage and navigating to login");
        localStorage.clear();
        navigate('/login');
      } else {
        console.log("Unexpected response code:", response.data.response.code);
        setFilteredData(null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData(null);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const steps = exerciseDetails?.data?.steps_image_collection;
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const nextStep = () =>
    setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
  const prevStep = () => setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  const handleLevelChange = (level, rounds, duration) => {
    setSelectedLevel(level);
    setSelectedRounds(rounds);
    setSelectedDuration(duration);
  };

  // useEffect(() => {
  //   sendEventsToAnalyticsSystems("exercise_start_screen", {
  //     exerciseName: exercise_enum,
  //     // exerciseId: exercise.exercise_id,
  //     exerciseType: exercise.exercise_type,
  //   });
  // }, [exercise_enum, exercise.exercise_type]);

  const handlePreStart = () => {
    // sendEventsToAnalyticsSystems("exercise_pre_start_tap", {
    //   exerciseName: exercise_enum,
    //   // exerciseId: exercise.exercise_id,
    //   exerciseType: exercise.exercise_type,
    // });
  };

  

  const seoData = getSeoData(filteredData?.exercise_description);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <SeoHelmet title={seoData.title} description={seoData.description} />
      <div className="sm:hidden">
        <InstallAppHeader />
      </div>

      <div className=" mt-[5rem]">
        <div
          onClick={() => navigate(-1)}
          className="flex mb-4 items-center justify-center text-center relative"
        >
          <img src={BackArrow} className="absolute left-5" alt="Back" />
          <p className="text-center text-xs text-[#A1A1A1] ">
            {exerciseDetails?.data?.exercise_title}
          </p>
        </div>
        <div className="mb-5">
          <p className="text-center text-2xl font-semibold">
            {exerciseDetails?.data?.exercise_description}
          </p>
        </div>
        <LearnMoreExercise
          exercise={filteredData}
          ImageURL={ImageURL}
          openModal={openModal}
        />
      </div>
      <div className="m-6 p-3 bg-white border-[1px] border-slate-100 rounded-lg ">
        <h3 className="text-sm font-semibold text-[#505050]">Choose a level</h3>
        <LevelTable
          levelInformation={exerciseDetails?.data?.level_information}
          selectedLevel={selectedLevel}
          handleLevelChange={handleLevelChange}
        />
      </div>
      <div className="p-4 fixed w-full bottom-0">
        <p className="text-center text-lg mb-3">
          1 breathing round <span className="font-bold"> = 10 seconds</span>
        </p>
        <Link
          to={`/breathing-exercises/${exerciseDetails?.data?.exercise_enum.replace(/_/g, '-')}/video`}
          state={{
            exercise: exercise,
            selectedRounds,
            selectedLevel,
            selectedDuration,
            selectedAccurateTime,
          }}
        >
          <button
            type="button"
            onClick={handlePreStart}
            className="text-white w-full text-xl bg-button-bg-color hover:bg-button-bg-color focus:outline-none focus:ring-4  font-semibold rounded-full  px-5 py-2.5 text-center me-2 mb-2 "
          >
            <div className="flex items-center gap-2 justify-center">
              <img src={playicon} />
              Next
            </div>{" "}
          </button>
        </Link>
      </div>

      {isOpen && (
        <ExerciseModal
          isOpen={isOpen}
          closeModal={closeModal}
          steps={exerciseDetails?.data?.steps_image_collection || []}
          currentStep={currentStep}
          prevStep={prevStep}
          nextStep={nextStep}
          ImageURL={ImageURL}
          exerciseDetails={exerciseDetails}
          exercise={exercise}
          selectedRounds={selectedRounds}
          selectedLevel={selectedLevel}
          selectedDuration={selectedDuration}
        />
      )}
    </>
  );
};

export default ExerciseDetailsPage;
