import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import premiumSuccess from "../../assests/icons/premiumsuccess.png";
import Lottie from "lottie-react";
import welcome from "../../assests/json/welcome.json";
import { useLocation } from 'react-router-dom';
import {
  sendEventsToAnalyticsSystems,} from "../../utils/analytics";
const PaymentSuccessScreen = () => {
  const location = useLocation();
  const verificationData = location.state?.verificationData?.data;
  console.log(verificationData,'sendEventsToAnalyticsSystems')
  const date =  new Date(verificationData?.expiration_date);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  const newFormattedDate = date.toLocaleDateString('en-US', options);
  useEffect(() => {
    sendEventsToAnalyticsSystems("exercise_purchased",{
      Source : verificationData?.plan_name,
      PlanOption : verificationData?.plan_name,
      playType : verificationData?.plan_name,
      // FAQTapValue: faqTitle
    });
    
  }, []);
  return (
    // <div>
    //     <div>

    //     </div>
    //     <div>

    //     </div>
    //     <div>

    //     </div>
    // </div>
    <div className="flex flex-col items-center justify-center ">
      <div className=" rounded-lg text-center w-full px-4">
        <div className="flex flex-col items-center mb-4">
        <Lottie
            animationData={welcome}
            style={{ width: "90%", height: '90%' }}
          />
          <p className="text-xl font-normal">to</p>
          <div className="flex items-center justify-center mb-2">
            <img
              src={`${process.env.REACT_APP_S3_BUCKET_URL}/${verificationData?.image}`}
              width="104px"
              height="216px"
              className="max-w-[104px]"
            />
          </div>

          <p>{verificationData?.sub_title}</p>
        </div>

        <div className="">
          <h2 className="text-[#7B7B7B] uppercase text-[10px] font-normal tracking-wide mb-3">
            PLAN Details
          </h2>
          <div className="border-[1px] border-[#F3F3F3] px-6 py-4 rounded-xl shadow-lg">
            <div className="flex justify-between items-center border-b-[1px] pb-3 border-full">
              <p className="text-xs font-semibold uppercase">PLAN</p>
              <p className="text-sm font-semibold">{verificationData?.plan_name}</p>
            </div>
            <div className="flex justify-between items-center pt-3">
              <p className="text-xs font-semibold uppercase">Valid till</p>
              <p className="text-sm font-semibold">{newFormattedDate}</p>
            </div>
          </div>
        </div>
        {/* <div className="w-full mb-4">
          <h2 className="text-left font-semibold">PLAN DETAILS</h2>
          <div className="bg-gray-200 p-4 rounded-md shadow">
            <p className="text-sm">PLAN</p>
            <p className="font-medium">Yearly</p>
            <p className="text-sm">VALID TILL</p>
            <p className="font-medium">11 July 2023</p>
          </div>
        </div> */}
        <Link to="/">
          <button className="border text-xl font-semibold border-purple-500 text-purple-500 mt-4 rounded-full py-3 px-4 w-full">
            Okay, got it
          </button>
        </Link>
        <a href="https://share.cdhl.in/breathing-in-app">
          <button className="bg-purple-500 text-xl font-semibold text-white rounded-full mt-2 py-3 px-4 w-full mb-2  ">
            Download app!
          </button>
        </a>
      </div>
    </div>
  );
};

export default PaymentSuccessScreen;
